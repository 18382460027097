:root {
  --main-bg-color: #73808a;
}

body {
  margin: 0;
  /* font-family: "Georgia", "serif"; */
  background-color: #73808a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rich-text-display .ProseMirror {
  padding: 10px;
}

.rich-text-display .ProseMirror-focused {
  outline: none;
}

.remirror-editor-wrapper div[contenteditable="false"] {
  box-shadow: none;
}

ol {
  list-style-type: decimal;
}

ol > li > ol {
  list-style-type: lower-alpha;
}

ol > li > ol > li > ol {
  list-style-type: lower-roman;
}
